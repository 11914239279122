import { useState, useEffect, Fragment, useRef, useCallback } from "react";
import { useRouter } from "next/router";
import {
  doc,
  getDocs,
  collection,
  query,
  where,
  orderBy,
  documentId,
  limit,
  startAfter,
  DocumentData,
  QueryDocumentSnapshot,
  getCountFromServer,
} from "firebase/firestore";
import { toast } from "react-toastify";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useCollection, useDocument } from "react-firebase-hooks/firestore";
import { useWindowSize, useFavicon } from "react-use";
import * as Sentry from "@sentry/browser";
import { NextSeo } from "next-seo";

import { db } from "utils/firebase";
import {
  Feed,
  Layout,
  Loader,
  Avatar,
  CollectionGroup,
} from "components/common";
import { useAuth } from "context/AuthContext";
import { subscribeToUser } from "pages/api/subscription";
import Link from "next/link";
import Image from "next/image";
import { Unauthenticated } from "components/common";
import { getInitials, withBreaksAndLinks } from "utils/textUtils";
import usePremiumStatus from "hooks/app/usePremiumStatus";
import {
  MAX_SUBSCRIPTIONS_PER_SECOND,
  SOFT_LIMIT_SUBSCRIPTIONS_PER_SECOND,
} from "constants/hardLimits";
import { BASE_URL } from "constants/redirects";
import EditProfilePopup from "components/Profile/EditProfilePopup";
import RegistrationPopup from "components/Profile/RegistrationPopup";

export async function getServerSideProps(context) {
  const { req } = context;
  const host = req?.headers?.host || "";

  const user = await getDocs(
    query(
      collection(db, "publicUsers"),
      where("customDomainUrl", "==", host ? `https://${host}` : "0")
    )
  );
  const userDoc = user?.docs[0];
  const userData = userDoc?.data() || {};

  const serializedUserData = JSON.parse(JSON.stringify(userData));

  return {
    props: {
      userProfile: serializedUserData || "0",
      userProfileId: userDoc?.id || "0",
    },
  };
}

const navigation = [
  { name: "All Posts", href: "?filter=everything", current: true },
];

const filters = {
  everything: ["text", "video", "image", "audio", "article"],
  text: ["text"],
  visual: ["video", "image"],
  audio: ["audio"],
  other: ["article"],
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function UserProfile({ userProfile, userProfileId }) {
  const { user, logout } = useAuth();
  const userIsPremium = usePremiumStatus(user);
  const { width } = useWindowSize();
  const router = useRouter();
  const routerQuery = router.query;
  const pathname = router.pathname;
  const profileId = routerQuery?.profileId?.toString() || "";

  const origin =
    typeof window !== "undefined" && window.location.origin
      ? window.location.origin
      : BASE_URL;

  const menuButtonRef = useRef(null);

  // Temp hard limits
  const [firstSubscription, setFirstSubscription] = useState(null);
  const [numberOfSubscriptions, setNumberOfSubscriptions] = useState(0);

  const [name, setName] = useState<string>("");
  const [username, setUsername] = useState<string>("");
  const [bio, setBio] = useState<string>("");
  const [profileImage, setProfileImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [customFavicon, setCustomFavicon] = useState(null);
  const [ctaText, setCtaText] = useState("");
  const [ctaLink, setCtaLink] = useState("");
  const [userPostCount, setUserPostCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscribing, setIsSubscribing] = useState(false);

  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [localPostData, setLocalPostData] = useState<any[]>([]);
  const [localUserData, setLocalUserData] = useState();
  const [lastVisiblePost, setLastVisiblePost] =
    useState<QueryDocumentSnapshot<DocumentData>>(null);
  const [totalPostCount, setTotalPostCount] = useState(0);

  const [editProfileModalOpen, setEditProfileModalOpen] = useState(false);
  const [registrationModalOpen, setRegistrationModalOpen] = useState(false);

  useFavicon(customFavicon || null);

  const [updatedProfile] = useDocument(
    doc(db, "publicUsers", userProfileId || "0")
  );
  const updatedProfileData = updatedProfile?.data();

  const filterQuery = routerQuery?.filter
    ? routerQuery?.filter.toString() || ""
    : "";

  const collectionQuery = routerQuery?.collection
    ? routerQuery?.collection.toString() || ""
    : "";

  const [isSubscribedDoc] = useDocument(
    doc(db, `subscriptions`, `${user?.uid}_${userProfileId}`)
  );
  const isSubscribed = isSubscribedDoc?.exists();

  const basePinnedPostQuery = query(
    collection(db, "posts"),
    where("authorId", "==", userProfileId || "0"),
    where("pinned", "in", [1, 2, 3, 4, 5, 6]),
    where(
      "type",
      "array-contains-any",
      filterQuery
        ? filters[filterQuery]
        : ["text", "video", "image", "audio", "article"]
    )
  );

  const pinnedPostQueryWithCollectionFilter = query(
    basePinnedPostQuery,
    where("collection", "==", collectionQuery)
  );

  const [pinnedPosts, pinnedPostsLoading] = useCollection(
    query(
      collectionQuery
        ? pinnedPostQueryWithCollectionFilter
        : basePinnedPostQuery
    )
  );

  const initialUserPostLimit = !!pinnedPosts?.docs?.length
    ? 10 - pinnedPosts?.docs?.length
    : 10;

  const basePostQuery = query(
    collection(db, "posts"),
    where("authorId", "==", userProfileId || "0"),
    orderBy("userPostCounterId", "desc"),
    where("isArchived", "==", false),
    where(
      "type",
      "array-contains-any",
      filterQuery
        ? filters[filterQuery]
        : ["text", "video", "image", "audio", "article"]
    )
  );

  const postQueryWithCollectionFilter = query(
    basePostQuery,
    where("collection", "==", collectionQuery)
  );

  const [initialUserPosts, initialUserPostLoading] = useCollection(
    query(
      collectionQuery ? postQueryWithCollectionFilter : basePostQuery,
      limit(initialUserPostLimit)
    )
  );

  const [userPosts, userPostLoading] = useCollection(
    lastVisiblePost
      ? query(
          collectionQuery ? postQueryWithCollectionFilter : basePostQuery,
          startAfter(lastVisiblePost),
          limit(10)
        )
      : null
  );

  const [usersData, usersDataLoading] = useCollection(
    query(
      collection(db, "publicUsers"),
      where(documentId(), "==", userProfileId || "0")
    )
  );

  useEffect(() => {
    if (
      !pinnedPostsLoading &&
      !initialUserPostLoading &&
      !userPostLoading &&
      !usersDataLoading &&
      (initialUserPosts?.docs.length === 0 || usersData.docs.length > 0) &&
      !initialLoadComplete
    ) {
      setInitialLoadComplete(true);
    }
  }, [
    pinnedPostsLoading,
    initialUserPostLoading,
    userPostLoading,
    usersDataLoading,
    initialLoadComplete,
    usersData?.docs.length,
    initialUserPosts?.docs.length,
  ]);

  useEffect(() => {
    if (userProfile?.name || updatedProfileData?.name)
      setName(
        updatedProfileData?.name !== undefined
          ? updatedProfileData?.name
          : userProfile.name
      );
    if (
      updatedProfileData?.isOnboarded &&
      (userProfile?.username || updatedProfileData?.username)
    )
      setUsername(updatedProfileData?.username || userProfile.username);
    if (userProfile?.bio || updatedProfileData?.bio)
      setBio(
        updatedProfileData?.bio !== undefined
          ? updatedProfileData?.bio
          : userProfile.bio
      );
    if (userProfile?.postCount || updatedProfileData?.postCount)
      setUserPostCount(
        updatedProfileData?.postCount !== undefined
          ? updatedProfileData?.postCount
          : userProfile.postCount
      );
    if (userProfile?.profileImageUrl || updatedProfileData?.profileImageUrl)
      setProfileImage(
        updatedProfileData?.profileImageUrl !== undefined
          ? updatedProfileData?.profileImageUrl
          : userProfile.profileImageUrl
      );
    if (userProfile?.coverImageUrl || updatedProfileData?.coverImageUrl)
      setCoverImage(
        updatedProfileData?.coverImageUrl !== undefined
          ? updatedProfileData?.coverImageUrl
          : userProfile.coverImageUrl
      );
    if (userProfile?.customFaviconUrl || updatedProfileData?.customFaviconUrl)
      setCustomFavicon(
        updatedProfileData?.customFaviconUrl !== undefined
          ? updatedProfileData?.customFaviconUrl
          : userProfile.customFaviconUrl
      );
    if (userProfile?.ctaText) setCtaText(userProfile.ctaText);
    if (userProfile?.ctaLink) setCtaLink(userProfile.ctaLink);

    setIsLoading(false);
  }, [userProfile, updatedProfileData]);

  useEffect(() => {
    const countQuery = lastVisiblePost
      ? query(
          collectionQuery ? postQueryWithCollectionFilter : basePostQuery,
          startAfter(lastVisiblePost)
        )
      : query(collectionQuery ? postQueryWithCollectionFilter : basePostQuery);
    const getTotalCount = async () =>
      await getCountFromServer(countQuery).then((snap) =>
        setTotalPostCount(snap.data().count)
      );
    getTotalCount();
  }, [
    basePostQuery,
    collectionQuery,
    filterQuery,
    lastVisiblePost,
    postQueryWithCollectionFilter,
    userProfileId,
  ]);

  useEffect(() => {
    setInitialLoadComplete(false);
  }, [filterQuery, collectionQuery]);

  useEffect(() => {
    setLastVisiblePost(null);
    setLocalPostData([]);

    const pinnedPostsData = pinnedPosts?.docs || [];
    const orderedPinnedPostsData = pinnedPostsData.sort((a, b) =>
      a.data().pinned > b.data().pinned ? 1 : -1
    );
    const initialUserPostsData = initialUserPosts?.docs || [];

    // Dedupe posts
    setLocalPostData((localPostData) => {
      return orderedPinnedPostsData
        .concat(initialUserPostsData)
        .reduce((acc, current) => {
          const x = acc.find((item) => item.id === current.id);
          if (!x) {
            return acc.concat([current]);
          } else {
            return acc;
          }
        }, []);
    });
    menuButtonRef?.current && menuButtonRef.current.blur();
  }, [pinnedPosts, initialUserPosts, filterQuery, collectionQuery]);

  useEffect(() => {
    setLocalPostData((localPostData) =>
      localPostData.concat(userPosts?.docs || [])
    );
    menuButtonRef?.current && menuButtonRef.current.blur();
  }, [userPosts]);

  const handleSubscribe = async (registerUid) => {
    // Temp hard limits
    const now = new Date().getTime();

    if (!firstSubscription || now - 1000 >= firstSubscription) {
      setFirstSubscription(now);
      setNumberOfSubscriptions(1);
    } else {
      if (numberOfSubscriptions >= MAX_SUBSCRIPTIONS_PER_SECOND) {
        toast.error(`Please try again in 10 seconds.`);
        return;
      }
      setNumberOfSubscriptions((subscriptions) => subscriptions + 1);

      if (numberOfSubscriptions >= SOFT_LIMIT_SUBSCRIPTIONS_PER_SECOND) {
        Sentry.captureMessage(`Soft limit subscriptions reached - ${user.uid}`);
      }
    }

    if ((registerUid || user?.uid) && userProfileId) {
      setIsSubscribing(true);
      await subscribeToUser(registerUid || user.uid, userProfileId).finally(
        () => setIsSubscribing(false)
      );
    } else {
      toast.error("You need to be logged in to subscribe to a user");
    }
  };

  const getSubscribeText = () => {
    if (ctaText) return ctaText;
    return isSubscribed ? "Unsubscribe" : "Subscribe";
  };
  const getSubscribeButtonStyles = () =>
    isSubscribed
      ? "bg-white text-orange-600 hover:bg-gray-100 border-orange-600"
      : "bg-orange-600 text-white hover:bg-orange-700 ";

  const getFilterTitle = () => {
    if (filterQuery === "text") return "Text";
    if (filterQuery === "visual") return "Photo/video";
    if (filterQuery === "audio") return "Audio";
    if (filterQuery === "other") return "Other";
    return "Filter";
  };

  const isOptionActive = (option) => {
    if (option === "text" && filterQuery === "text") return true;
    if (option === "visual" && filterQuery === "visual") return true;
    if (option === "audio" && filterQuery === "audio") return true;
    if (option === "other" && filterQuery === "other") return true;
    if (
      option == "everything" &&
      (!filterQuery || filterQuery === "everything")
    )
      return true;
    return false;
  };

  const handleFetchNextData = () => {
    if (userPosts?.docs.length) {
      setLastVisiblePost(userPosts.docs[userPosts.docs.length - 1]);
    } else if (initialUserPosts?.docs.length) {
      setLastVisiblePost(
        initialUserPosts.docs[initialUserPosts.docs.length - 1]
      );
    }
  };

  const handleEditProfileOpen = useCallback(
    (setOpen: boolean) => setEditProfileModalOpen(setOpen),
    []
  );

  const handleEditProfileClick = () => setEditProfileModalOpen(true);

  const handleRegistrationOpen = useCallback(
    (setOpen: boolean) => setRegistrationModalOpen(setOpen),
    []
  );

  const handleRegistrationClick = () => setRegistrationModalOpen(true);

  const handleAddPostClick = () => {
    if (userIsPremium) {
      router.push({
        pathname: router.pathname,
        query: { ...router.query, publish: "standard" },
      });
    } else {
      router.push({
        pathname: router.pathname,
        query: { ...router.query, getPremium: "true" },
      });
    }
  };

  const renderActionButtons = () => {
    if (user?.uid === userProfileId) {
      return (
        <div className="flex items-center justify-center space-x-4">
          <button
            onClick={handleEditProfileClick}
            type="button"
            className="flex w-full items-center justify-center rounded-full border border-gray-400 border-transparent  bg-white px-4 py-2 text-center text-xs  text-gray-500 hover:bg-gray-100  focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:text-sm"
          >
            Edit Profile
          </button>

          <button
            onClick={handleAddPostClick}
            type="button"
            className="hidden flex-row content-center items-center justify-center rounded-full border border-transparent bg-orange-600  py-2 px-4 text-xs  font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 sm:flex sm:px-7 sm:text-sm"
          >
            <Image
              className="mr-2 h-3 w-auto sm:h-4"
              src="/images/starlo_stars_white.svg"
              alt="Starlo Stars"
              width={20}
              height={20}
              priority
            />
            <span>Publish</span>
          </button>
        </div>
      );
    }

    if (ctaText) {
      if (ctaLink)
        return (
          <div className="flex items-center justify-center space-x-4">
            <Link
              target="_blank"
              href={ctaLink}
              className={`${getSubscribeButtonStyles()} inline-flex items-center rounded-full border border-transparent px-6 py-2 text-sm font-medium leading-4  shadow-md  focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2`}
            >
              {ctaText}
            </Link>
          </div>
        );
    }

    if (user) {
      return (
        <div className="flex items-center justify-center space-x-4">
          <button
            type="button"
            className={`${getSubscribeButtonStyles()} inline-flex items-center rounded-full border border-transparent px-6 py-2 text-sm font-medium leading-4  shadow-md  focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-70`}
            onClick={() => handleSubscribe(null)}
            disabled={isSubscribing}
          >
            {getSubscribeText()}
          </button>
        </div>
      );
    }

    return (
      <div className="flex items-center justify-center space-x-4">
        <button
          type="button"
          onClick={handleRegistrationClick}
          className={`${getSubscribeButtonStyles()} inline-flex items-center rounded-full border border-transparent px-6 py-2 text-sm font-medium leading-4  shadow-md  focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2`}
        >
          Subscribe
        </button>
      </div>
    );
  };

  const handleCollectionSelection = (id: string) => {
    if (id) {
      router.push({
        pathname,
        query: { ...routerQuery, collection: id },
      });
    } else {
      const { collection, ...queryWithoutCollection } = routerQuery;

      router.push({
        pathname,
        query: queryWithoutCollection,
      });
    }
  };

  const getMetaTitle = () => {
    if (userProfile?.metaTitle && userProfile?.isOnboarded) {
      return `${userProfile?.metaTitle}`;
    }
    if (userProfile?.username && userProfile?.isOnboarded) {
      return `${userProfile?.username} | Starlo`;
    }
    return "Starlo";
  };

  const getMetaDescription = () => {
    if (userProfile?.metaDescription) {
      return userProfile?.metaDescription;
    }
    if (userProfile?.bio) {
      return userProfile?.bio;
    }
    if (userProfile?.name) {
      return `${userProfile?.name}'s profile`;
    }
    return "Starlo profile";
  };

  const getMetaImageAlt = () => {
    if (userProfile?.name) {
      return `${userProfile?.name}'s profile image`;
    }
    return "Starlo profile image";
  };

  const getH1Title = () => {
    if (userProfile?.username && userProfile?.isOnboarded) {
      if (userProfile?.name) {
        return `${userProfile?.name} (@${userProfile?.username})`;
      }
      return userProfile?.username;
    }
    return "Starlo";
  };

  return (
    <Layout isFullScreen>
      <NextSeo
        title={getMetaTitle()}
        description={getMetaDescription()}
        canonical={`${origin}/${profileId}${filterQuery}`}
        openGraph={{
          title: getMetaTitle(),
          description: getMetaDescription(),
          type: "profile",
          profile: { username: userProfile?.name },
          url: `${origin}/${profileId}${filterQuery}`,
          images: [
            {
              url:
                userProfile?.ogImageUrl ??
                userProfile?.profileImageUrl ??
                `${BASE_URL}/starlo-meta-image.jpg`,
              width: 1200,
              height: 630,
              alt: getMetaImageAlt(),
            },
          ],
        }}
      />

      {pathname === "/profile" && !user ? (
        <Unauthenticated />
      ) : (
        <div className="flex min-h-full w-full flex-col items-center justify-start">
          <div className="sm:mx-auto sm:w-full sm:max-w-md">
            <h1 className="sr-only">{getH1Title()}</h1>
          </div>
          <div className="relative flex w-full flex-col items-center justify-center ">
            {coverImage ? (
              <Image
                className="h-64 max-h-64 w-full object-cover"
                referrerPolicy="no-referrer"
                src={coverImage}
                alt="Cover photo"
                width={width}
                height={width / 4}
                priority
              />
            ) : (
              <div className="h-64 max-h-64 w-full bg-gray-200 object-cover" />
            )}

            <div className="absolute top-44 flex w-full justify-center px-4 ">
              <div className="w-full max-w-3xl sm:px-6 lg:px-8">
                <span className="relative">
                  <span className="absolute h-36 w-36 rounded-full bg-black opacity-[0.05] sm:h-40 sm:w-40"></span>
                  <div className="hidden sm:flex">
                    <Avatar
                      initials={getInitials(name)}
                      imageUrl={profileImage}
                      size="4xl"
                      withBorder
                    />
                  </div>
                  <div className="flex sm:hidden">
                    <Avatar
                      initials={getInitials(name)}
                      imageUrl={profileImage}
                      size="3xl"
                      withBorder
                    />
                  </div>
                </span>
              </div>
            </div>

            <div className="relative mt-24 flex w-full flex-col items-center justify-center  sm:mt-32 ">
              <div className="flex w-full flex-col items-center justify-center ">
                <div className="flex w-full max-w-3xl flex-row justify-between px-4 sm:px-6 lg:px-8">
                  <div className="space flex flex-col">
                    <div className="text-xl font-bold text-black sm:mb-1 sm:text-3xl">
                      {name}
                    </div>
                    {username ? (
                      <div className="mb-3 text-base font-normal text-black sm:mb-5">{`@${username}`}</div>
                    ) : null}
                  </div>

                  <div>{renderActionButtons()}</div>
                </div>

                <div className="w-full max-w-3xl px-4 sm:px-6 lg:px-8">
                  <div className="mb-8 text-base font-normal text-black">
                    {withBreaksAndLinks(bio)}
                  </div>
                </div>

                <nav
                  className="mb-1 flex w-full flex-col items-center justify-center"
                  aria-label="Feed navigation"
                >
                  <div className="flex w-full max-w-3xl justify-between space-x-4 px-4 sm:space-x-8 sm:px-6 lg:px-8">
                    {navigation.map((item) => (
                      <Link
                        key={item.name}
                        href={`/${profileId}${item.href}`}
                        className={classNames(
                          item.current
                            ? "bg-gray-100 text-gray-900"
                            : "text-gray-900 hover:bg-gray-50 hover:text-gray-900",
                          "inline-flex min-w-[20%] items-center justify-center whitespace-nowrap rounded-md py-2 px-3 text-center text-sm font-medium"
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        {item.name}
                      </Link>
                    ))}

                    <Menu
                      as="div"
                      className="relative inline-block w-28 text-left"
                    >
                      <div>
                        <Menu.Button
                          ref={menuButtonRef}
                          className="inline-flex w-full justify-between rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                        >
                          {getFilterTitle()}
                          <ChevronDownIcon
                            className="-mr-1 ml-2 h-5 w-5"
                            aria-hidden="true"
                          />
                        </Menu.Button>
                      </div>

                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-28 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <div className="py-1">
                            <Menu.Item>
                              <Link
                                href={{
                                  pathname,
                                  query: {
                                    ...routerQuery,
                                    filter: "everything",
                                  },
                                }}
                                className={classNames(
                                  isOptionActive("everything")
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Everything
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link
                                href={{
                                  pathname,
                                  query: {
                                    ...routerQuery,
                                    filter: "text",
                                  },
                                }}
                                className={classNames(
                                  isOptionActive("text")
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Text
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link
                                href={{
                                  pathname,
                                  query: {
                                    ...routerQuery,
                                    filter: "visual",
                                  },
                                }}
                                className={classNames(
                                  isOptionActive("visual")
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Photo/video
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link
                                href={{
                                  pathname,
                                  query: {
                                    ...routerQuery,
                                    filter: "audio",
                                  },
                                }}
                                className={classNames(
                                  isOptionActive("audio")
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Audio
                              </Link>
                            </Menu.Item>
                            <Menu.Item>
                              <Link
                                href={{
                                  pathname,
                                  query: {
                                    ...routerQuery,
                                    filter: "other",
                                  },
                                }}
                                className={classNames(
                                  isOptionActive("other")
                                    ? "bg-gray-100 text-gray-900"
                                    : "text-gray-700",
                                  "block px-4 py-2 text-sm"
                                )}
                              >
                                Other
                              </Link>
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>

                  <div className="mt-2 w-full border-b"></div>

                  {updatedProfileData?.collections?.length > 0 && (
                    <>
                      <div className="mt-6 w-full max-w-3xl ">
                        <CollectionGroup
                          onChange={handleCollectionSelection}
                          collections={updatedProfileData?.collections}
                          defaultCollection={collectionQuery}
                        />
                      </div>
                      <div className="w-full border-b"></div>{" "}
                    </>
                  )}
                </nav>
              </div>
            </div>

            <div className="w-full py-3 md:max-w-3xl">
              {!initialLoadComplete ? (
                <div className="sm:py-8 sm:px-6 lg:px-8">
                  <Loader />
                </div>
              ) : (
                <>
                  <Feed
                    postsData={localPostData}
                    usersData={usersData?.docs}
                    fetchNextData={handleFetchNextData}
                    hasMoreData={localPostData?.length < totalPostCount}
                    isProfileFeed
                  />
                </>
              )}
            </div>
          </div>

          {user?.uid === userProfileId && (
            <EditProfilePopup
              open={editProfileModalOpen}
              setOpen={handleEditProfileOpen}
            />
          )}

          {!user?.uid && (
            <RegistrationPopup
              open={registrationModalOpen}
              setOpen={handleRegistrationOpen}
              userProfileId={userProfileId}
              handleSubscribe={handleSubscribe}
            />
          )}
        </div>
      )}
    </Layout>
  );
}

export default UserProfile;
