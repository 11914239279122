import { db } from "utils/firebase";
import {
  doc,
  runTransaction,
  serverTimestamp,
} from "firebase/firestore";

const subscribeToUser = async (userId: string, subscriptionId: string) => {
  try {
    await runTransaction(
      db,
      async (subscriptionTransaction) => {
        const userRef = doc(db, "users", userId);
        const userSnap = await subscriptionTransaction.get(userRef);
        const subscriptionRef = doc(
          db,
          "subscriptions",
          `${userId}_${subscriptionId}`
        );
        const subscriptionSnap = await subscriptionTransaction.get(
          subscriptionRef
        );

        if (!userSnap.exists()) {
          throw new Error("User does not exist!");
        }

        // Add or remove subscription
        if (subscriptionSnap.exists()) {
          subscriptionTransaction.delete(subscriptionRef);

          return false;
        } else {
          subscriptionTransaction.set(subscriptionRef, {
            subscriberId: userId,
            subscriptionId,
            createdAt: serverTimestamp(),
            lastPostedAt: 0,
          });

          return true;
        }
      }
    ).then(() => Promise.resolve());
  } catch (err) {
    throw new Error("Not allowed", err);
  }
};

export { subscribeToUser };
