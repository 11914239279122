import {
  Fragment,
  useState,
  useEffect,
  SyntheticEvent,
  FormEvent,
  useCallback,
} from "react";
import Image from "next/image";
import { doc } from "firebase/firestore";
import { Dialog, Transition } from "@headlessui/react";
import Dropzone from "react-dropzone";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { throttle } from "throttle-debounce";
import { toast } from "react-toastify";
import { useDocument } from "react-firebase-hooks/firestore";
import * as Sentry from "@sentry/browser";
import { useRouter } from "next/router";

import { db } from "utils/firebase";
import { storage } from "utils/firebase";
import { dataURItoBlob } from "utils/imageUtils";
import { getInitials, validateURL } from "utils/textUtils";
import { Avatar, Loader } from "components/common";
import usePremiumStatus from "hooks/app/usePremiumStatus";
import { useAuth } from "context/AuthContext";
import { XMarkIcon } from "@heroicons/react/24/outline";
import { updateUser, checkUsernameExists } from "pages/api/user";
import { USER_VALIDATIONS } from "constants/validationRules";
import {
  MAX_EDIT_ACCOUNT_PER_SECOND,
  SOFT_LIMIT_EDIT_ACCOUNT_PER_SECOND,
} from "constants/hardLimits";
import Link from "next/link";
interface EditProfilePopupProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

function EditProfilePopup({ open, setOpen }: EditProfilePopupProps) {
  const { user } = useAuth();
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);

  const handleCloseModal = () => {
    setIsLoading(false);
    setOpen(false);
  };

  const userIsPremium = usePremiumStatus(user);

  const [userProfile] = useDocument(doc(db, `users`, user?.uid || "0"));

  // Temp hard limits
  const [firstChange, setFirstChange] = useState(null);
  const [numberOfChanges, setNumberOfChanges] = useState(0);

  const [name, setName] = useState<string>("");
  const [bio, setBio] = useState<string>("");
  const [profileImage, setProfileImage] = useState(null);
  const [coverImage, setCoverImage] = useState(null);
  const [backButtonText, setBackButtonText] = useState<string>("");
  const [backButtonUrl, setBackButtonUrl] = useState<string>("");

  const [password, setPassword] = useState<string>("");

  useEffect(() => {
    const userProfileData = userProfile?.data();

    if (userProfileData?.name) setName(userProfileData.name);
    if (userProfileData?.bio) setBio(userProfileData.bio);
    if (userProfileData?.profileImageUrl)
      setProfileImage(userProfileData.profileImageUrl);
    if (userProfileData?.coverImageUrl)
      setCoverImage(userProfileData.coverImageUrl);
    if (userProfileData?.backButtonText)
      setBackButtonText(userProfileData.backButtonText);
    if (userProfileData?.backButtonUrl)
      setBackButtonUrl(userProfileData.backButtonUrl);

    setIsLoading(false);
  }, [userProfile]);

  const handleSave = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Temp hard limits
    const saveTime = new Date().getTime();

    if (!firstChange || saveTime - 1000 >= firstChange) {
      setFirstChange(saveTime);
      setNumberOfChanges(1);
    } else {
      if (numberOfChanges >= MAX_EDIT_ACCOUNT_PER_SECOND) {
        toast.error("`Please try again in 10 seconds.`");
        return false;
      } else {
        setNumberOfChanges((changes) => changes + 1);

        if (numberOfChanges >= SOFT_LIMIT_EDIT_ACCOUNT_PER_SECOND) {
          Sentry.captureMessage(
            `Soft limit account edit reached - ${user.uid}`
          );
        }
      }
    }

    if (isLoading) return;
    setIsLoading(true);

    const validate = (boolResult: boolean, errorMessage: string): boolean => {
      if (!boolResult) {
        setIsLoading(false);
        toast.error(errorMessage);
        return false;
      }

      return true;
    };

    if (
      !validate(
        name.length >= USER_VALIDATIONS.nameMinLength,
        "Please enter a valid name."
      )
    )
      return;
    if (
      !validate(
        name.length <= USER_VALIDATIONS.nameMaxLength,
        `Your username cannot exceed ${USER_VALIDATIONS.nameMaxLength} characters.`
      )
    )
      return;

    if (
      !validate(
        bio.length <= USER_VALIDATIONS.bioMaxLength,
        `Your bio cannot exceed ${USER_VALIDATIONS.bioMaxLength} characters.`
      )
    )
      return;

    if (
      !validate(
        backButtonText.length <= USER_VALIDATIONS.backButtonTextMaxLength,
        `Your back button text cannot exceed ${USER_VALIDATIONS.backButtonTextMaxLength} characters.`
      )
    )
      return;

    if (user?.uid) {
      if (
        profileImage &&
        profileImage !== userProfile?.data()?.profileImageUrl
      ) {
        const profileStorageRef = ref(storage, `users/${user.uid}/profile`);

        const profileUploadTask = uploadBytesResumable(
          profileStorageRef,
          dataURItoBlob(profileImage)
        );

        await profileUploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            toast.error("There was a problem uploading your profile image.");
            setIsLoading(false);
          },
          () => {
            getDownloadURL(profileUploadTask.snapshot.ref).then(
              (downloadURL) => {
                setProfileImage(downloadURL);

                updateUser({
                  docId: user.uid,
                  details: {
                    profileImageUrl: downloadURL,
                  },
                })
                  .then(() => {})
                  .catch((err) => {
                    toast.error("Something went wrong");
                    console.error(err);
                  });
              }
            );
          }
        );
      } else if (!profileImage) {
        updateUser({
          docId: user.uid,
          details: {
            profileImageUrl: "",
          },
        })
          .then(() => {})
          .catch((err) => {
            toast.error("Something went wrong");
            console.error(err);
          });
      }

      if (coverImage && coverImage !== userProfile?.data()?.coverImageUrl) {
        const coverStorageRef = ref(storage, `users/${user.uid}/cover`);
        const coverUploadTask = uploadBytesResumable(
          coverStorageRef,
          dataURItoBlob(coverImage)
        );

        await coverUploadTask.on(
          "state_changed",
          (snapshot) => {},
          (error) => {
            toast.error("There was a problem uploading your cover image.");
            setIsLoading(false);
          },
          () => {
            getDownloadURL(coverUploadTask.snapshot.ref).then((downloadURL) => {
              setCoverImage(downloadURL);

              updateUser({
                docId: user.uid,
                details: {
                  coverImageUrl: downloadURL,
                },
              })
                .then(() => {})
                .catch((err) => {
                  toast.error("Something went wrong");
                  console.error(err);
                });
            });
          }
        );
      } else if (!coverImage) {
        updateUser({
          docId: user.uid,
          details: {
            coverImageUrl: "",
          },
        })
          .then(() => {})
          .catch((err) => {
            toast.error("Something went wrong");
            console.error(err);
          });
      }

      await updateUser({
        docId: user.uid,
        details: {
          name,
          bio,
          backButtonText,
          backButtonUrl,
        },
      })
        .then(() => {
          toast.success("Saved!");
          router.push(`/`);
          setOpen(false);
        })
        .catch((err) => {
          toast.error("Something went wrong");
          console.error(err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  const hasChanges = () =>
    userProfile?.data()?.name !== name ||
    userProfile?.data()?.bio !== bio ||
    !userProfile?.data()?.profileImageUrl ||
    userProfile?.data()?.profileImageUrl !== profileImage ||
    !userProfile?.data()?.coverImageUrl ||
    userProfile?.data()?.coverImageUrl !== coverImage ||
    userProfile?.data()?.backButtonText !== backButtonText ||
    userProfile?.data()?.backButtonUrl !== backButtonUrl;

  const handleNameChange = (e: FormEvent<HTMLInputElement>) =>
    setName(e.currentTarget.value);

  const handleBioChange = (e: FormEvent<HTMLTextAreaElement>) =>
    setBio(e.currentTarget.value);

  const onProfilePhotoDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader();
    if (!acceptedFiles.length) toast.error("Unsupported image");

    reader.onabort = () => console.log("File reading was aborted");
    reader.onerror = () => console.log("File reading has failed");
    reader.onloadend = () => {
      setProfileImage(reader.result);
    };
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  const handleRemoveProfileImage = () => setProfileImage(null);

  const onCoverPhotoDrop = useCallback((acceptedFiles) => {
    const reader = new FileReader();
    if (!acceptedFiles.length) toast.error("Unsupported image");

    reader.onabort = () => console.log("File reading was aborted");
    reader.onerror = () => console.log("File reading has failed");
    reader.onloadend = () => {
      setCoverImage(reader.result);
    };
    reader.readAsDataURL(acceptedFiles[0]);
  }, []);

  const handleRemoveCoverImage = () => setCoverImage(null);

  const handleBackButtonTextChange = (e: FormEvent<HTMLInputElement>) => {
    setBackButtonText(
      e.currentTarget.value.substring(
        0,
        USER_VALIDATIONS.backButtonTextMaxLength
      )
    );
  };

  const handleBackButtonUrlChange = (e: FormEvent<HTMLInputElement>) => {
    setBackButtonUrl(`https://${e.currentTarget.value}`);
  };

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={handleCloseModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 ">
          <div
            className={`flex  min-h-full w-screen items-center justify-center  text-center sm:items-center sm:p-10`}
          >
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel
                className={`relative  flex h-full max-h-screen w-full max-w-3xl transform flex-col items-center justify-start  overflow-hidden rounded-lg   bg-white text-left shadow-xl transition-all sm:max-h-[80vh] `}
              >
                <form
                  className="relative flex w-full overflow-hidden"
                  action="#"
                  method="POST"
                  onSubmit={handleSave}
                >
                  <div className="fixed top-2 flex w-full max-w-3xl flex-row justify-between bg-white">
                    <div className="flex flex-row items-center">
                      <button
                        type="button"
                        className="ml-2 inline-flex items-center rounded-md bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                        onClick={handleCloseModal}
                      >
                        <span className="sr-only">Close</span>
                        <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                      </button>

                      <h4 className="ml-4  whitespace-nowrap text-lg font-medium text-gray-700">
                        Edit Profile
                      </h4>
                    </div>

                    <div className="mx-2">
                      {isLoading ? (
                        <Loader />
                      ) : (
                        <button
                          disabled={!hasChanges() || isLoading}
                          type="submit"
                          className="flex w-full justify-center rounded-md border border-transparent bg-orange-600 py-2 px-6 text-sm font-medium text-white shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-orange-600 disabled:opacity-70"
                        >
                          Save
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="mt-14 w-full overflow-y-auto">
                    <div className="relative">
                      <div className="relative">
                        <div className="sr-only  block text-sm font-medium text-gray-700">
                          Cover photo
                        </div>
                        {coverImage ? (
                          <div className="group relative mt-2 flex  w-full cursor-pointer flex-col items-center justify-center overflow-hidden text-gray-600 hover:bg-gray-400">
                            <Image
                              src={coverImage}
                              className="h-64 max-h-64 w-full object-cover group-hover:opacity-30"
                              alt="Cover image"
                              width={1024}
                              height={256}
                            />
                            <button
                              className="absolute top-0 left-0 box-content h-full w-full rounded-lg border-0 border-transparent text-white opacity-0 transition-opacity group-hover:opacity-100"
                              onClick={handleRemoveCoverImage}
                            >
                              Remove
                            </button>
                          </div>
                        ) : (
                          <Dropzone
                            onDrop={onCoverPhotoDrop}
                            accept={{
                              "image/png": [".png", ".gif", ".jpeg", ".jpg"],
                            }}
                            maxFiles={1}
                            maxSize={5000000}
                            noClick
                          >
                            {({ getRootProps, getInputProps, open }) => (
                              <div
                                className="mt-2 flex w-full items-center justify-center"
                                {...getRootProps()}
                              >
                                <label
                                  htmlFor="dropzone-file"
                                  className="flex h-64 w-full cursor-pointer flex-col items-center justify-center border-y-2 border-dashed border-gray-300 bg-gray-50 hover:bg-gray-100  "
                                >
                                  <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                    <svg
                                      aria-hidden="true"
                                      className="mb-3 h-10 w-10 text-gray-400"
                                      fill="none"
                                      stroke="currentColor"
                                      viewBox="0 0 24 24"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        strokeWidth="2"
                                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                      ></path>
                                    </svg>
                                    <p className="mb-2 text-sm text-gray-500">
                                      <span className="font-semibold text-orange-500">
                                        Click to upload
                                      </span>{" "}
                                      or drag and drop
                                    </p>
                                    <p className="mb-0 text-xs text-gray-500">
                                      PNG, JPG or GIF up to 5mb
                                    </p>
                                  </div>
                                  <input
                                    {...getInputProps()}
                                    accept="image/*"
                                    id="dropzone-file"
                                    type="file"
                                    className="hidden"
                                    onClick={open}
                                  />
                                </label>
                                <div className="absolute right-2 bottom-2 text-sm text-gray-400">
                                  1600 x 400 px
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        )}
                      </div>

                      <div className="absolute top-44 left-14 flex flex-row items-center">
                        <div className="sr-only block text-sm font-medium text-gray-700">
                          Photo
                        </div>
                        <div className="mt-2 flex flex-row content-center">
                          {profileImage ? (
                            <div className="group relative flex w-full cursor-pointer flex-col items-center justify-center overflow-hidden rounded-full border-2 border-white bg-white text-gray-600 hover:bg-gray-400">
                              <div className="group-hover:opacity-30">
                                <Avatar
                                  initials={getInitials(name)}
                                  imageUrl={profileImage}
                                  size="3xl"
                                />
                              </div>

                              <button
                                onClick={handleRemoveProfileImage}
                                className="absolute top-0 left-0 box-content h-full w-full rounded-full border-0 border-transparent border-white text-white opacity-0 transition-opacity group-hover:opacity-100"
                              >
                                Remove
                              </button>
                            </div>
                          ) : (
                            <Dropzone
                              onDrop={onProfilePhotoDrop}
                              accept={{
                                "image/png": [".png", ".gif", ".jpeg", ".jpg"],
                              }}
                              maxFiles={1}
                              maxSize={5000000}
                              noClick
                            >
                              {({ getRootProps, getInputProps, open }) => (
                                <div
                                  className=" flex h-36 w-36 items-center justify-center rounded-full border-2 border-transparent "
                                  {...getRootProps()}
                                >
                                  <label
                                    className="flex h-36 w-36 flex-wrap items-center justify-center text-sm font-medium text-gray-700"
                                    htmlFor="profilePhoto"
                                  >
                                    <input
                                      {...getInputProps()}
                                      onClick={open}
                                      accept="image/*"
                                      type="file"
                                      id="profilePhoto"
                                      className="flex w-full cursor-pointer flex-wrap content-center justify-center text-sm text-gray-900 file:mr-4 file:cursor-pointer file:rounded-md file:border file:border-solid file:border-gray-300 file:bg-white file:px-3 file:py-1 focus:outline-none  file:focus:border-orange-500 file:focus:outline-none  file:focus:ring-orange-500 dark:text-gray-400 dark:placeholder-gray-400 "
                                    />
                                    <div className="flex h-full w-full cursor-pointer flex-col items-center justify-center rounded-full border-2  border-dashed border-gray-300 bg-white text-gray-700 hover:bg-gray-100 focus:border-orange-500 focus:outline-none focus:ring-orange-500">
                                      Upload
                                      <div className=" text-sm text-gray-400">
                                        160 x 160 px
                                      </div>
                                    </div>
                                  </label>
                                </div>
                              )}
                            </Dropzone>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-10 space-y-8 py-16 px-4 sm:px-8">
                      <div>
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Name
                        </label>
                        <div className="mt-1">
                          <input
                            id="name"
                            name="name"
                            type="text"
                            autoComplete="name"
                            required
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                            onChange={handleNameChange}
                            maxLength={USER_VALIDATIONS.nameMaxLength}
                            disabled={isLoading}
                            value={name.substring(
                              0,
                              USER_VALIDATIONS.nameMaxLength
                            )}
                          />
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="bio"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Bio
                        </label>
                        <div className="mt-1">
                          <textarea
                            rows={4}
                            name="bio"
                            id="bio"
                            maxLength={USER_VALIDATIONS.bioMaxLength}
                            value={bio.substring(
                              0,
                              USER_VALIDATIONS.bioMaxLength
                            )}
                            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                            onChange={handleBioChange}
                          />
                        </div>
                        <div className="mt-1 text-sm text-gray-400">
                          Write a few sentences about yourself.
                        </div>
                      </div>

                      <div>
                        <h3 className="text-lg font-medium">
                          Profile Navigation Button
                        </h3>
                        <div className="text-sm text-gray-400">
                          Add an optional navigation button to your custom
                          profile.
                        </div>
                      </div>

                      <div>
                        <label
                          htmlFor="backButtonText"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Back Button Text
                        </label>
                        <div className="mt-1">
                          <input
                            id="backButtonText"
                            name="backButtonText"
                            type="text"
                            autoComplete="backButtonText"
                            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-orange-500 focus:outline-none focus:ring-orange-500 sm:text-sm"
                            onChange={handleBackButtonTextChange}
                            maxLength={USER_VALIDATIONS.backButtonTextMaxLength}
                            disabled={isLoading}
                            value={backButtonText}
                          />
                        </div>

                        <label
                          htmlFor="backButtonUrl"
                          className="mt-4 block text-sm font-medium text-gray-700"
                        >
                          Back Button URL
                        </label>
                        <div className="mt-1 flex rounded-md shadow-sm">
                          <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
                            https://
                          </span>
                          <input
                            id="backButtonUrl"
                            name="backButtonUrl"
                            type="text"
                            autoComplete="backButtonUrl"
                            className="block w-full min-w-0 flex-1 rounded-none rounded-r-md border-gray-300 px-3 py-2 focus:border-orange-500 focus:ring-orange-500 sm:text-sm"
                            onChange={handleBackButtonUrlChange}
                            disabled={isLoading}
                            value={backButtonUrl.substring(
                              8,
                              backButtonUrl.length
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="px-3 pb-10">
                      <Link
                        href="/settings"
                        className="flex w-full justify-center rounded-md border border-transparent bg-gray-100 py-2 px-3 text-sm font-medium leading-4 text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                      >
                        See more settings
                      </Link>
                    </div>
                  </div>
                </form>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default EditProfilePopup;
